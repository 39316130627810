import React from 'react';
import { Link } from 'gatsby';

export default function PageNotFound() {
  return (
    <div className="pure-g">
      <div className="pure-u-1">
        <Link to="/">Back to Home Page.</Link>
      </div>
    </div>
  );
}
